import config from 'config'
import { authApiClient, customerApiClient } from 'api'
const uuidv4 = require('uuid/v4');
const API = {}

API.login = ({ email, password, clientId, redirectUrl, captchaToken, callback }) => {

  const payload = {
    email,
    password,
    clientId,
    redirectUrl,
    captchaToken
  }

  return authApiClient.post('/authenticate/code', payload)
    .then(callback)
    .catch((error) => callback(error.response))
}

API.sendReset = (email, captchaToken, callback) => {
  return customerApiClient.post('/users/forgot', { email, captchaToken })
  .then(callback)
  .catch((error)=> {
    callback(error.response)
    //do some more error tracking
  })
}

API.resetPassword = (newPassword, relayToken, callback) => {
  const newPasswordPayload = {
    newPassword,
    relayToken
  }
  return authApiClient.post(`/password/update/forgot?clientId=${config.DEFAULT_CLIENT_ID}&redirectUrl=${config.APP_ROOT}`, newPasswordPayload)
    .then(callback)
    .catch((error) => callback(error.response))
}

const getThirdPartyLoginPayload = (code) => {
  return {
    'code' : code,
    'clientId' : config.DEFAULT_CLIENT_ID,
    'anonymousId' : !!window.analytics && !!window.analytics.user ? window.analytics.user().anonymousId() : uuidv4()
  }
}

API.exchangeGoogleCodeForDriftCode = (code, captureCode, captureError) => {
  const payload = getThirdPartyLoginPayload(code)
  return authApiClient.post('google/oauth/accept', payload)
    .then(response => captureCode(response))
    .catch(error => captureError(error.response))
}

API.exchangeMicrosoftCodeForDriftCode = (code, captureCode, captureError) => {
  const payload = getThirdPartyLoginPayload(code)
  return authApiClient.post('microsoft/oauth/accept', payload)
    .then(response => captureCode(response))
    .catch(error => captureError(error.response))
}

API.checkHyFyMigrationEligible = (email, callback, onError) => {
  return authApiClient.get(`/identity/video?email=${email}`)
    .then(callback)
    .catch(onError)
}

API.redeemMultifactorCode = (payload, callback) => {
  return authApiClient.post(`/authenticate/multifactor/redeem`, payload)
    .then(callback)
    .catch((error) => callback(error.response))
}

API.enableGoogleAuthenticator = (payload, callback) => {
  return authApiClient.post(`/google-auth/setup`, payload)
    .then(callback)
    .catch((error) => callback(error.response))
}

API.enableSMSAuth = (payload, callback) => {
  return authApiClient.post(`/sms-auth/setup`, payload)
    .then(callback)
    .catch((error) => callback(error.response))
}

API.resendCode = (payload, callback) => {
  return authApiClient.post(`/sms-auth/resend`, payload)
    .then(callback)
    .catch((error) => callback(error.response))
}

API.getRelayTokenUserInfo = (relayToken, captureError) => {
  return authApiClient.post('/relay/info', {"token": relayToken})
    .catch(error => captureError(error))
}

API.verifyEmailAddressUpdate = (relayToken) => {
  return customerApiClient.patch(`/users/verifyEmail?relayToken=${relayToken}`)
}

export default API
