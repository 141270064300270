import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import IsMobile from 'ismobilejs'
import { get, includes } from 'lodash'
import { setSnippets } from 'utils/snippets'
import { Div } from '@driftt/tide-core'
import config from 'config'
import urlParse from 'url-parse'
import logo from 'assets/svg/logo.svg'

import driftSalesloftBrandLogo from 'assets/images/drift_salesloft_brand_logo.svg'
import driftLogoSubstract from 'assets/images/logo_substract.svg'
import driftVerticalText from 'assets/images/drift_vertical_text.svg'
import './style.css'

const RESET_PASSWORD_ROUTES = ['/reset', '/forgot-password']
const MOBILE_VIDEO_REDIRECT_URL = '/letsgo/video/success'

const MarketingSplash = () => (
  <Div>
    <Div className="drift-salesloft-logo-content">
      <Div className="drift-vertical-text">
        <img src={driftVerticalText} alt="vertical text" />
      </Div>
      <Div className="logo-container">
        <Div className="drift-salesloft-logo">
          <img src={driftSalesloftBrandLogo} alt="company logo" />
        </Div>
        <Div className="drift-abstract">
          <img src={driftLogoSubstract} alt="dirt logo" />
        </Div>
      </Div>
    </Div>
  </Div>
)

const getClientId = (url) => {
  const shouldParseQuery = true
  return get(
    urlParse(url, shouldParseQuery),
    'query.client_id',
    config.DEFAULT_CLIENT_ID
  )
}

const getRedirectUrl = (url) => {
  const shouldParseQuery = true
  return get(
    urlParse(url, shouldParseQuery),
    'query.redirect',
    config.DEFAULT_REDIRECT_URL
  )
}

class Auth extends Component {
  componentWillReceiveProps(nextProps) {
    this.redirectToLogin(nextProps)
  }

  componentWillMount() {
    if (
      !includes(RESET_PASSWORD_ROUTES, this.props.location.pathname) &&
      !includes(
        Object.keys(config.CUSTOM_CLIENTS),
        getClientId(this.props.location.search)
      ) &&
      !getRedirectUrl(this.props.location.search).includes(
        MOBILE_VIDEO_REDIRECT_URL
      )
    ) {
      if (IsMobile.apple.phone) {
        this.props.history.push('/download-ios-app')
        return
      } else if (IsMobile.android.phone || IsMobile.seven_inch) {
        this.props.history.push('/download-android-app')
        return
      }
    }

    this.redirectToLogin(this.props)
  }

  componentDidMount() {
    setSnippets()
  }

  redirectToLogin(props) {
    if (props.location.pathname === '/') {
      props.history.push(`/login${this.props.location.search}`)
    }
  }

  render() {
    return (
      <Div className="drift-auth-app">
        <Div className="drift-auth-container">
          <Div className="drift-auth-content">
            <img src={logo} alt="Drift" className="logo" />
            {this.props.children}
          </Div>
        </Div>
        <MarketingSplash />
      </Div>
    )
  }
}

const AuthLayout = withRouter(Auth)

export default AuthLayout
