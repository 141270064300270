import React, { Component } from 'react'
import { Div, H2, P } from '@driftt/tide-core'
import "./styles.css"

class ProviderOption extends Component {

  selectProvider = () => {
    this.props.selectProvider(this.props.providerType)
  }

  render() {
    const {
      providerImage,
      providerTitle,
      providerText
    } = this.props

    return (
      <Div
        className="provider-option-container"
        onClick={this.selectProvider}
      >
        <Div className="provider-image-container">
          <img className="provider-image" alt="multifactor provider" src={providerImage}/>
        </Div>
        <Div className="provider-info">
          <H2>{providerTitle}</H2>
          <P>{providerText}</P>
        </Div>
      </Div>
    )
  }
}

export default ProviderOption
