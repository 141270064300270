import React, { Component } from 'react'
import SetupInstruction from '../SetupInstruction'
import { Button, Input, Div, H1, P, A } from '@driftt/tide-core';
import { Icon } from '@driftt/tide-icons'
import { Link } from 'react-router-dom'
import mobileDevices from 'assets/svg/phone-and-tablet.svg'
import enterCode from 'assets/svg/code.svg'
import QRCode from 'qrcode.react';
import './styles.css'


class GoogleAuthenticatorSetupStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      setupStep: "GET_APP",
      showCode: false,
    }
  }

  getQRCode = () => {
    const { email, multifactorSecret} = this.props
    const val = `otpauth://totp/Drift:${email}?secret=${multifactorSecret}&issuer=Drift`
    return <QRCode className="qr-code" value={val}/>
  }

  setCode = ({ target }) => {
    this.props.setCode(target.value)
  }

  nextStep = () => {
    this.props.setupMultifactor()
    this.setState({ setupStep: "ENTER_CODE" })
  }

  toggleDisplayCode = () => {
    this.setState({showCode: !this.state.showCode})
  }

  getSetupInstructionText = (stepNumber, multifactorSecret) => {
    if (stepNumber === 1) {
      return (
        <P>
          Download and install the
          <A href="https://apps.apple.com/us/app/google-authenticator/id388497605">
             {" Google Authenticator"}
          </A> app for your phone or tablet.
        </P>
      )
    } else if (stepNumber === 2) {
      return (
        <Div>
          <P>
            Open the authentication app and scan the barcode on the left using your phone’s camera.
          </P>
          { this.state.showCode ?
            <Div className="manual-entry-code">
              <P>Use the following manual entry key:</P>
              <P className="mfa-secret">{multifactorSecret}</P>
            </Div>
            :
            <A className="show-code-text" onClick={this.toggleDisplayCode}>Having trouble scanning?</A>
          }
        </Div>
      )
    } else if (stepNumber === 3) {
      return <P>Once the barcode is scanned, enter the 6-digit verification code generated by the app.</P>
    }
  }

  render() {
    const {
      setupStep
    } = this.state

    const {
      multifactorCode,
      multifactorSecret,
      submitCode,
      errorMessage,
      backToSelect
    } = this.props

    if (setupStep === "GET_APP") {
      return (
        <Div className="setup-mfa-container">
          <Link
            className="solo-link mfa-back-button"
            to={{}}
            onClick={backToSelect}>
            <Icon width={12} height={8} name="arrow-1-left" />
            Back
          </Link>
          <H1 className="setup-mfa-header no-margin">
            Set up Google Authenticator
          </H1>
          <P className="setup-mfa-info">
            Once configured, you’ll be required to enter a code created
            by the Google Authenticator app in order to sign into your Drift account.
          </P>
          <SetupInstruction
            stepTitle={"Get the app"}
            stepText={this.getSetupInstructionText(1, multifactorSecret)}
            stepNumber={1}
            qrCode={false}
            stepImage={mobileDevices}
          />
          <Button className="mfa-next-step-button" size="medium" onClick={this.nextStep}>
            Next step
          </Button>
        </Div>
      )
    } else if (setupStep === "ENTER_CODE") {
      return (
        <Div className="setup-mfa-container">
          <SetupInstruction
            stepTitle={"Scan this barcode"}
            stepText={this.getSetupInstructionText(2, multifactorSecret)}
            stepNumber={2}
            multifactorSecret={multifactorSecret}
            qrCode={true}
            stepImage={this.getQRCode()}
          />
          <SetupInstruction
            stepTitle={"Enter your verification code below"}
            stepText={this.getSetupInstructionText(3, multifactorSecret)}
            stepNumber={3}
            qrCode={false}
            stepImage={enterCode}
          />
          <Input
            size="medium"
            value={multifactorCode}
            onChange={this.setCode}
            className="mfa-code-input"
            placeholder="123456"
          />
          { !!errorMessage &&
            <strong className="request-status error setup">
              {errorMessage}
            </strong>
          }
          <Button className="mfa-next-step-button" size="medium" onClick={submitCode} disabled={!multifactorCode}>
            Verify code and enable
          </Button>
        </Div>
      )
    }
  }
}

export default GoogleAuthenticatorSetupStep
