import React, { Component } from 'react'
import PropTypes from 'prop-types'
import enterCode from 'assets/svg/code.svg'
import { Input, Button, Div, H1, P } from '@driftt/tide-core'
import './styles.css'
import { MFA_PROVIDERS } from '../../constants.js'


class MFAProviderStep extends Component {

  static propTypes = {
    code: PropTypes.string,
    setCode: PropTypes.func.isRequired
  }

  setCode = ({ target }) => {
    this.props.setCode(target.value)
  }

  getProviderInfo = () => {
    if (this.props.provider === MFA_PROVIDERS.google) {
      return "Use the Google Authenticator app to access your authentication code for Drift."
    } else if (this.props.provider === MFA_PROVIDERS.sms) {
      return "We sent an authentication code to your phone for signing into Drift."
    }
  }

  render() {
    const {
      code,
      submitCode,
      errorMessage,
    } = this.props

    return (
      <Div className="enter-mfa-container">
        <H1 className="enter-mfa-header">Enter your authentication code to sign in</H1>
        <P className="enter-mfa-info">{this.getProviderInfo()}</P>
        <Div className="enter-code-image">
          <img src={enterCode} alt="enter code"/>
        </Div>
        <Div className="enter-code-input">
          <Input
            className="code-input"
            size="medium"
            value={code}
            onChange={this.setCode}
            placeholder="123456"
          />
          <Button className="submit-button" size="medium" onClick={submitCode} disabled={!code}>
            Verify code
          </Button>
          { !!errorMessage &&
            <strong className="request-status error verify">
              {errorMessage}
            </strong>
          }
        </Div>
      </Div>
    )
  }
}

export default MFAProviderStep
