import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Input, Div, H1 } from '@driftt/tide-core'
import * as recaptcha from 'utils/recaptcha'
import './style.css'


class PasswordLoginStep extends Component {

  constructor(props) {
    super(props)
    this.state = {
      solvedCaptcha: false
    }
  }

  static propTypes = {
    password: PropTypes.string,
    setPassword: PropTypes.func.isRequired,
    goBackToEmailStep: PropTypes.func.isRequired,
    submitLogin: PropTypes.func.isRequired,
    forgotPasswordState: PropTypes.string.isRequired,
    errorMessage: PropTypes.string
  }

  setSolvedCaptcha = (value) => {
    this.setState({ solvedCaptcha: value })
  }

  componentDidMount() {
    recaptcha.initialize(this.setSolvedCaptcha)
    const passwordInputField = document.getElementById("password")
    passwordInputField && passwordInputField.focus()
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.errorMessage && this.props.errorMessage) {
      this.setState({ solvedCaptcha: false }, recaptcha.reset)
    }
  }

  setPassword = ({ target }) => {
    this.props.setPassword(target.value)
  }

  submitLogin = (event) => {
    event.preventDefault()
    this.props.submitLogin({ captchaToken : recaptcha.getToken() })
  }

  render() {
    const {
      password,
      forgotPasswordState,
      errorMessage,
      goBackToEmailStep,
      loginLoading
    } = this.props
    const { solvedCaptcha } = this.state
    const submitDisabled = !solvedCaptcha || !password
    return (
      <Div className="password-login-step">
        <H1>Enter your password</H1>
        <form className="drift-form" onSubmit={this.submitLogin}>
          <Input
            size="large"
            value={password}
            onChange={this.setPassword}
            className="large email-form"
            id="password"
            name="password"
            type="password"
            placeholder="Your password"
            required
            autoComplete="on"
          />
          {errorMessage &&
            <strong className="request-status error">
              {errorMessage}
            </strong>
          }
          <div className="recaptcha-container">
            <div id="g-recaptcha" />
          </div>
          <Button 
            size="large" 
            onClick={this.submitLogin} 
            loading={loginLoading} 
            disabled={submitDisabled}
          >
            Sign in
          </Button>
        </form>

        <Link
          className="solo-link"
          to={{
            pathname: '/forgot-password',
            state: { forgotPasswordState }
          }}>
          I forgot my password
        </Link>
        <Button
          className="solo-link"
          type="utility"
          icon="arrow-1-left"
          onClick={goBackToEmailStep}
        >
            Back
        </Button>
      </Div>
    )
  }
}

export default PasswordLoginStep
