import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import config from 'config'
import MessageConstants from 'constants/MessageConstants.js'
import SsoErrorConstants from 'constants/SsoErrorConstants.js'
import OAuthConstants from 'constants/OAuthConstants.js'
import EmailLoginStep from './EmailLoginStep'
import PasswordLoginStep from './PasswordLoginStep'
import MultifactorLoginStep from './MultifactorLoginStep'
import API from '../../../api/auth'
import { tryResolveLoginPageUrl, resolveSsoRedirectUrl } from 'api/sso'
import { contains } from 'ramda'
import urlParse from 'url-parse'
import GoogleAuth from './GoogleAuth/'
import MicrosoftAuth from './MicrosoftAuth'
import { MFA_PROVIDERS } from './constants'
import { includes } from 'lodash'
import { setRedirectInfo, getRedirectUrl, getClientId, cleanUpRedirectInfo } from '../../../utils/redirectUrl'
import SessionTimeoutBanner from './SessionTimeoutBanner'
import { resolveRegionString } from 'utils/region'
import Goodbye from './Goodbye'
import { pathOr } from 'ramda'

const parseUrl = (url) => {
  const shouldParseQuery = true
  return urlParse(url, shouldParseQuery)
}

const getPath = (url) => {
  const shouldParseQuery = false
  return urlParse(url, shouldParseQuery).pathname
}

const resolveCode = (location) => {
  const shouldParseQuery = true
  return urlParse(location.search, shouldParseQuery).query.code
}

const resolveClientId = (location) => {
  const clientId = parseUrl(location.search).query.client || parseUrl(location.search).query.client_id
  return clientId
}

const resolvePartner = (location) => {
  const { partner } = parseUrl(location.search).query
  return config.VALID_PARTNERS.includes(partner) && partner
}

const resolveRedirectUrl = (location) => {
  const redirectUrl = parseUrl(location.search).query.redirect || parseUrl(location.search).query.redirect_uri

  if (!redirectUrl) { return }

  const parsedRedirectUrl = parseUrl(redirectUrl)

  return (contains(parsedRedirectUrl.hostname, config.WHITELISTED_REDIRECT_SUBDOMAINS))
    ? redirectUrl
    : config.DEFAULT_REDIRECT_URL
}

const resolveDestinationString = (clientId) => {
  if(document.referrer.includes('dev.drift')) {
    return 'Dev.Drift with your Drift account'
  } else if(clientId in config.CUSTOM_CLIENTS) {
    return config.CUSTOM_CLIENTS[clientId]
  }

  return 'Drift'
}

const resolveDefaultLoginMessage = (clientId, partner) => {
  return !!partner ? `In partnership with ${partner}` : `Sign in to ${resolveDestinationString(clientId)}`
}

const resolveState = (location) => {
  return parseUrl(location.search).query.state
}

const resolveSSOError = (location) => {
  const ssoErrorCode = parseUrl(location.search).query.ssoErrorCode
  const requestId = parseUrl(location.search).query.requestId

  if (!!ssoErrorCode && !!requestId && ssoErrorCode.match(/^[A-Z_]+$/) && requestId.match(/^[0-9a-z]+$/)) {

    switch (ssoErrorCode) {
      case 'INVALID_CONFIGURATION':
        return SsoErrorConstants.INVALID_CONFIGURATION + SsoErrorConstants.REQUEST_ID_MESSAGE + requestId
      case 'SESSION_EXPIRED':
        return SsoErrorConstants.SESSION_EXPIRED + SsoErrorConstants.REQUEST_ID_MESSAGE + requestId
      default:
        return null
    }
  } else {
    return null
  }
}

const ActiveStep = {
  EMAIL: 0,
  PASSWORD: 1,
  MULTIFACTOR: 2,
  HIDDEN: 3
}

class Login extends Component {

  constructor(props) {
	  super(props)
    const defaultMessage = this.props.location.state ? this.props.location.state.message || '' : ''

    setRedirectInfo(resolveRedirectUrl(this.props.location), resolveClientId(this.props.location),)

    this.state = {
      errorMessage: null,
      emailErrorMessage: resolveSSOError(this.props.location),
      oAuthErrorMessage: OAuthConstants.NO_ERROR,
      email: '',
      password: '',
      message: defaultMessage,
      state: resolveState(this.props.location),
      thirdPartyLogin: this.resolveThirdPartyLogin(this.props.location),
      defaultLoginMessage: resolveDefaultLoginMessage(getClientId(), resolvePartner(this.props.location)),
      activeStep: ActiveStep.EMAIL,
      isCheckingForSsoLoginUrl: false,
      showTimeoutBanner: parseUrl(window.location.search).query.timeout === "true",
      hasDeletedAccount: parseUrl(window.location.search).query.deleteAccount === "true",
      loginLoading: false,
      emailVerifiedMessage: null,
	  }

    //this replaces the persisted message state.
    //in react router
    setTimeout(()=>{
      this.setState({ message: '' }, () => {
        this.props.history.replace(undefined , {})
      })
    }, 5000)
  }

  componentDidMount() {
    this.checkForEmailVerificationToken()
  }

  checkForEmailVerificationToken() {
    const urlQueryParams = urlParse(window.location.search, true).query
    const emailVerifyToken = urlQueryParams.emailVerifyToken
    if (emailVerifyToken) {
      const emailVerificationSuccessCallback = (response) => {
        this.setState({ emailVerifiedMessage: MessageConstants.EMAIL_VERIFICATION_SUCCESS, email: response.data.email })
      }
      const emailVerificationFailureCallback = (response) => {
        const errorMessage =  pathOr(MessageConstants.EMAIL_VERIFICATION_GENERIC_FAILURE, ['response', 'data', 'message'], response)
        if (errorMessage === MessageConstants.EMAIL_VERIFICATION_EMAIL_IN_USE_API_ERROR) {
          this.setState({ emailVerifiedMessage: MessageConstants.EMAIL_VERIFICATION_EMAIL_IN_USE_ERROR })
        } else if (errorMessage === MessageConstants.EMAIL_VERIFICATION_TOKEN_EXPIRED_API_ERROR) {
          this.setState({ emailVerifiedMessage: MessageConstants.EMAIL_VERIFICATION_TOKEN_EXPIRED_ERROR })
        } else {
          this.setState({ emailVerifiedMessage: MessageConstants.EMAIL_VERIFICATION_GENERIC_FAILURE })
        }
      }
      API.verifyEmailAddressUpdate(emailVerifyToken)
        .then(emailVerificationSuccessCallback)
        .catch(emailVerificationFailureCallback)
    }
  }

  setEmail = (email) => {
    this.setState({
      email,
      errorMessage: '',
      emailErrorMessage: ''
    })
  }

  setPassword = (password) => {
    this.setState({
      password,
      errorMessage: '',
      emailErrorMessage: ''
    })
  }

  setMultifactorCode = (code) => {
    this.setState({
      multifactorCode: code,
      errorMessage: '',
      emailErrorMessage: ''
    })
  }

  setMultifactorProvider = (multifactorProvider) => {
    this.setState({ multifactorProvider })
  }

  resolveNextUrlWithAuthCodeAndRegion = (authorizationCode) => {
    const regionString = resolveRegionString()
    const nextUrl = this.getNextUrl()
    const queryCharacter = nextUrl.includes('?') ? '&' : '?'
    return `${nextUrl}${queryCharacter}code=${authorizationCode}&region=${regionString}`
  }

  redirectToApp = (authorizationCode) => {
    const nextUrl = this.resolveNextUrlWithAuthCodeAndRegion(authorizationCode)
    window.location.replace(nextUrl)
  }

  getNextUrl = () => `${getRedirectUrl()}${this.state.state ? `?state=${this.state.state}` : ''}`

  submitLogin = ({ captchaToken }) => {
    const { email, password } = this.state
    const redirectUrl = getRedirectUrl()
    const clientId = getClientId()
    const loginCallback = (response) => {
      if (response.status === 200){
        const authCodeResponse = response.data
        if (authCodeResponse.multifactorRequired) {
          this.setState({
            activeStep: ActiveStep.MULTIFACTOR,
            multifactorProvider: authCodeResponse.multifactorProvider,
            multifactorToken: authCodeResponse.multifactorToken,
            multifactorSecret: authCodeResponse.multifactorSecret,
            availableProviders: authCodeResponse.availableProviders,
          })
        } else {
          const nextUrl = this.resolveNextUrlWithAuthCodeAndRegion(authCodeResponse.authorizationCode)
          cleanUpRedirectInfo()
          window.location.replace(nextUrl)
        }
      } else if (response.status === 403 && response.data.key === OAuthConstants.PASSWORD_RESET_REQUIRED) {
        const relayTokenPayload = JSON.parse(response.data.message)
        this.props.history.push(`/reset?token=${relayTokenPayload.relayToken}&adminReset=true`)
      } else if (response.status === 400) {
        if (response.data.message === OAuthConstants.INVALID_CAPTCHA_TOKEN) {
          this.setState({ 
            errorMessage: MessageConstants.CAPTCHA_TOKEN_EXPIRED,
            loginLoading: false
          })
        } else {
          this.setState({ 
            errorMessage: MessageConstants.LOGIN_BAD_INPUT,
            loginLoading: false
           })
        }
      }
    }
    this.setState({ loginLoading: true }, () => API.login({
      email,
      password,
      clientId,
      redirectUrl,
      captchaToken,
      callback: loginCallback.bind(this)
    }))
  }

  setupUserMultifactor = (data) => {
    if (this.state.multifactorProvider ===  MFA_PROVIDERS.google) {
      const payload = { token: this.state.multifactorToken }
      API.enableGoogleAuthenticator(payload, this.captureGoogleAuthSetupResponse)
    } else if (this.state.multifactorProvider === MFA_PROVIDERS.sms) {
      const payload = {
        relayToken: this.state.multifactorToken,
        phoneNumber: data
      }
      API.enableSMSAuth(payload, this.captureRelayTokenResponse)
    }
  }

  captureGoogleAuthSetupResponse = (response) => {
    if (response.data) {
      const setupResponse = response.data
      this.setState({
        multifactorToken: setupResponse.multifactorToken,
        multifactorSecret: setupResponse.multifactorSecret
      })
    }
  }

  captureRelayTokenResponse = (response) => {
    if (response.data && response.status === 200) {
      const setupResponse = response.data
      this.setState({
        multifactorToken: setupResponse.relayToken
      })
    }
  }

  submitMultifactorCode = () => {
    const { multifactorCode, multifactorToken, multifactorProvider } = this.state
    let payload = {
      relayToken: multifactorToken,
      userCode: multifactorCode,
      multifactorProvider: multifactorProvider
    }
    API.redeemMultifactorCode(payload, this.captureMFACode)
  }

  captureMFACode = (response) => {
    if (response.data && response.status === 200) {
      const multifactorResponse = response.data
      if (multifactorResponse.authorizationCode === "") {
        this.setState({
          multifactorToken: multifactorResponse.multifactorToken,
          errorMessage: "Your verification code is invalid"
        })
      } else {
        this.redirectToApp(multifactorResponse.authorizationCode)
      }
    } else {
      this.setState({
        errorMessage: "Error authorizing login attempt. Please refresh and try again."
      })
    }
  }

  resendCode = () => {
    API.resendCode(
      {
        token: this.state.multifactorToken
      },
      this.captureRelayTokenResponse)
  }

  redirectForSignUp(token) {
    const nextUrl = `${config.APP_ROOT}/letsgo/setup/touchless/?token=${token}&thirdPartySignup=true&region=US`
    window.location.replace(nextUrl)
  }

  redirectForInvitedUser(token) {
    const regionString = resolveRegionString()
    const nextUrl = `${config.APP_ROOT}/letsgo/setup?resetToken=${token}&region=${regionString}`
    window.location.replace(nextUrl)
  }

  redirectForSSO(email) {
    const nextUrl = this.getNextUrl()
    const authClientId = getClientId()
    resolveSsoRedirectUrl({ email: email, nextUrl, authClientId })
      .then((url) => window.location.replace(url))
      .catch(this.handleEmailSubmitError)
  }

  captureThirdPartyCode = (response) => {
    const thirdPartyLoginResponse = response.data
    const { message, value } = thirdPartyLoginResponse
    if (message && value) {
      switch (message) {
        case OAuthConstants.SUCCESS:
          this.redirectToApp(value)
          break
        case OAuthConstants.NEW_USER_SIGNUP:
          // Free org creation will only happen in US region
          // No need to pass region param
          this.redirectForSignUp(value)
          break
        case OAuthConstants.INVITED_USER_SIGNUP:
          this.redirectForInvitedUser(value)
          break
        case OAuthConstants.SSO_REDIRECT:
          // SSO flow determines region in its own flow
          this.redirectForSSO(value)
          break
        case OAuthConstants.INVITED_USER_SIGNUP_MISSED:
          this.setState({oAuthErrorMessage : OAuthConstants.INVITED_USER_DENY})
          break
        default:
          return
      }
    }
  }

  captureThirdPartyError = () => {
    this.setState({oAuthErrorMessage : OAuthConstants.GENERIC_ERR_MESSAGE})
  }


  googleLoginRequest = (location) => {
    return !!resolveCode(location)
  }

  microsoftLoginRequest = (location) => {
    if (includes(getPath(location), "microsoft")) {
      return !!resolveCode(location)
    }
    return false
  }

  renderErrorMessage = (oAuthErrorMessage) => {
    return (
      <div className="oauth-error-container">
        {oAuthErrorMessage()}
      </div>
    )
  }

  resolveThirdPartyLogin = (location) => {
    if (this.microsoftLoginRequest(location)) {
      API.exchangeMicrosoftCodeForDriftCode(resolveCode(location), this.captureThirdPartyCode, this.captureThirdPartyError)
    }
    else if (this.googleLoginRequest(location)) {
      API.exchangeGoogleCodeForDriftCode(resolveCode(location), this.captureThirdPartyCode, this.captureThirdPartyError)
    }
  }

  handleEmailSubmitError = (e) => {
    window.analytics && window.analytics.track("email submission failed", { email: this.state.email, authClientId: getClientId(), error: e.message });
    this.setState({
      isCheckingForSsoLoginUrl: false,
      emailErrorMessage: 'Whoops, make sure you\'ve entered a valid email address!'
    })
  }

  handleEmailSubmit = () => {
    const clientId = getClientId()
    const isDriftVideo = (clientId === config.DRIFT_VIDEO_CLIENT_ID) || (clientId === config.DRIFT_VIDEO_CLIENT_ID_DRIFT)
    if (isDriftVideo) {
      this.checkHyFyMigrationEligible(() => this.checkSsoAndMaybeRedirect())
    } else {
      this.checkSsoAndMaybeRedirect()
    }
  }

  checkSsoAndMaybeRedirect = () => {
    const onDone = ({ canLogIn }) => {
      if (!canLogIn) {
        this.setState({
          isCheckingForSsoLoginUrl: false,
          activeStep: ActiveStep.PASSWORD
        })
      } else {
        const nextUrl = this.getNextUrl()
        const authClientId = getClientId()
        resolveSsoRedirectUrl({ email: this.state.email, nextUrl, authClientId })
          .then((url) => window.location.replace(url))
          .catch(this.handleEmailSubmitError)
      }
    }

    const onStateSet = () => {
      tryResolveLoginPageUrl({ email: this.state.email })
        .then(onDone)
        .catch(this.handleEmailSubmitError)
    }

    this.setState({ isCheckingForSsoLoginUrl: true }, onStateSet)
  }

  checkHyFyMigrationEligible = (callback) => {
    const onError = ({ response }) => {
      if (response.status === 409) {
        this.setState({
          emailErrorMessage: 'Looks like you previously had a HYFY account. We’ve just sent a link to your email to log in to Drift Video!'
        })
      } else {
        callback()
      }
    }

    API.checkHyFyMigrationEligible(this.state.email, callback, onError)
  }

  hideSessionTimeoutBanner = () => {
    this.setState({ showTimeoutBanner: false })
  }

  hideLoginStep = () => {
    this.setState({ activeStep: ActiveStep.HIDDEN })
  }

  showEmailStep = () => {
    this.setState({ activeStep: ActiveStep.EMAIL })
  }

  showPasswordStep = () => {
    this.setState({ activeStep: ActiveStep.PASSWORD })
  }

  handlePasswordLoginStepVisibilityChange = (isVisible) => {
    if (isVisible) {
      const passwordInputField = document.getElementById("password")
      passwordInputField && passwordInputField.focus()
    }
  }

  render() {
    const {
      message,
      errorMessage,
      emailErrorMessage,
      oAuthErrorMessage,
      defaultLoginMessage,
      email,
      password,
      activeStep,
      isCheckingForSsoLoginUrl,
      multifactorProvider,
      multifactorCode,
      multifactorSecret,
      availableProviders,
      showTimeoutBanner,
      hasDeletedAccount,
      loginLoading,
      emailVerifiedMessage
    } = this.state

    return (
      <div className="login-page">
        {showTimeoutBanner && <SessionTimeoutBanner hideSessionTimeoutBanner={this.hideSessionTimeoutBanner}/>}
        {activeStep === ActiveStep.EMAIL &&
          <div>
            {!!oAuthErrorMessage() && this.renderErrorMessage(oAuthErrorMessage)}
            <EmailLoginStep
              email={email}
              setEmail={this.setEmail}
              onSubmit={this.handleEmailSubmit}
              title={message || defaultLoginMessage}
              isSubmittingEmail={isCheckingForSsoLoginUrl}
              errorMessage={emailErrorMessage}
              clientId={getClientId()}
              emailVerifiedMessage={emailVerifiedMessage}
        />
            <GoogleAuth
              captureCode={this.captureThirdPartyCode}
              captureError={this.captureThirdPartyError}
            />
            <MicrosoftAuth
              captureCode={this.captureThirdPartyCode}
              captureError={this.captureThirdPartyError}
            />
          </div>
        }

        { activeStep === ActiveStep.MULTIFACTOR &&
          <MultifactorLoginStep
            multifactorCode={multifactorCode}
            multifactorProvider={multifactorProvider}
            availableProviders={availableProviders}
            multifactorSecret={multifactorSecret}
            errorMessage={errorMessage}
            email={email}
            setCode={this.setMultifactorCode}
            setMultifactorProvider={this.setMultifactorProvider}
            setupMultifactor={this.setupUserMultifactor}
            submitCode={this.submitMultifactorCode}
            resendCode={this.resendCode}
          />
        }
        {
          hasDeletedAccount &&
          <Goodbye hideLoginStep={this.hideLoginStep}/>
        }
        { activeStep === ActiveStep.PASSWORD && 
          <PasswordLoginStep
            password={password}
            forgotPasswordState={email}
            errorMessage={errorMessage}
            setPassword={this.setPassword}
            submitLogin={this.submitLogin}
            loginLoading={loginLoading}
            goBackToEmailStep={this.showEmailStep}
          />
        }
      </div>
    )
  }
}

const LoginPage = withRouter(Login)

export default LoginPage
