import React, { Component } from 'react'
import config from 'config'
import { Button, Div } from '@driftt/tide-core'
import { encodeQueryData } from '../../../../utils/queryUtils'
import './styles.css'

const redirectToMicrosoftLogin = () => {
  window.analytics && window.analytics.track('Microsoft Login Window Opened', {
    anonymousId: !!window.analytics && !!window.analytics.user ? window.analytics.user().anonymousId() : 'none'
  })

  const params = {
    response_type: 'code',
    response_mode: 'query',
    redirect_uri: `${config.LOGIN_ROOT}/login/microsoft/`,
    client_id: config.MICROSOFT_CLIENT_ID,
    scope: 'openid profile email offline_access User.Read Calendars.ReadWrite',
  }
  window.location.href = `${config.MICROSOFT_OAUTH_BASE_URL}?${encodeQueryData(params)}`
}

class MicrosoftAuth extends Component {
  render() {
    return (
      <Div className="microsoft-login-container">
        <Button className="microsoft-login-button" onClick={() => redirectToMicrosoftLogin()} type="tertiary">
          <Div className="microsoft-login-logo">
            <img src={require('assets/svg/microsoft-logo.svg')} alt="microsoft logo" height="20" width="20" />
          </Div>
          <Div className="microsoft-login-text">
            Sign in with Microsoft
          </Div>
        </Button>
      </Div>
    )
  }
}

export default MicrosoftAuth
