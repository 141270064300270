import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Div, H2, P, A } from '@driftt/tide-core'
import driftLogo from 'assets/svg/logo.svg'
import driftLogoSmall from 'assets/svg/drift-logo.svg'
import backgroundLarge from 'assets/svg/grafitti-texture-full.svg'
import emailIcon from 'assets/svg/email.svg'
import IsMobile from 'ismobilejs'
import './styles.css'

const backgroundStyle = () => {
  if (IsMobile.phone) {
    return {
      background: `url(${backgroundLarge})`,
      backgroundRepeat: 'no-repeat'
    }
  }
}

class AcceptSuccessPage extends Component {
  render() {
    const {
      prospectEmail,
      userType
    } = this.props

    return (
      <Div className="drift-accept-success-container" style={backgroundStyle()}>
        <Div className="drift-logos">
          <img src={driftLogo} alt="Drift" className="driftLogo desktop-only" />
          <img src={driftLogoSmall} alt="Drift" className="driftLogoSmall mobile-only" />
        </Div>
        <Div>
          <img src={emailIcon} alt="Email sent" className="email-icon"/>
        </Div>
        <Div>
          <H2 className="invite-sent-header">Your invitation has been sent!</H2>
        </Div>
        <P className="invite-sent-text">
          <A className="email">{prospectEmail}</A> has been added to your Drift organization as a {userType} User.
        </P>
        <Div className="sign-in desktop-only">
          <A href="/login" className="sign-in-link">Sign in </A> to Drift to manage your teammates
        </Div>
      </Div>
    )
  }
}

export default withRouter(AcceptSuccessPage)
