import React, { Component } from 'react'
import { Div, H1, P } from '@driftt/tide-core'
import GoogleAuthenticatorSetupStep from './GoogleAuthenticatorSetupStep'
import SMSAuthSetupStep from './SMSAuthSetupStep'
import ProviderOption from './ProviderOption'
import googleAuthIcon from 'assets/svg/code.svg'
import smsAuthIcon from 'assets/svg/sms.svg'
import { MFA_PROVIDERS } from '../../constants.js'

class MFASetupStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProvider: false
    }
  }

  get hasSmsMfa() {
    return this.props.availableProviders.includes("SMS")
  }

  selectProvider = selectedProvider => {
    this.props.setMultifactorProvider(selectedProvider);
    this.setState({ selectedProvider })
    window.analytics.track('Multifactor Enabled', {
      clientId: this.state.clientId,
      provider: selectedProvider,
    })
  }

  backToSelect = () => {
    this.setState({ selectedProvider: false })
  }

  render() {
    const {
      selectedProvider
    } = this.state

    const {
      setCode,
      multifactorCode,
      multifactorSecret,
      setupMultifactor,
      submitCode,
      errorMessage,
      resendCode,
      email
    } = this.props

    if (selectedProvider === MFA_PROVIDERS.google) {
      return (
        <GoogleAuthenticatorSetupStep
          setCode={setCode}
          multifactorCode={multifactorCode}
          submitCode={submitCode}
          multifactorSecret={multifactorSecret}
          email={email}
          errorMessage={errorMessage}
          setupMultifactor={setupMultifactor}
          backToSelect={this.backToSelect}
        />
      )
    } else if (selectedProvider === MFA_PROVIDERS.sms) {
      return (
        <SMSAuthSetupStep
          setCode={setCode}
          multifactorCode={multifactorCode}
          submitCode={submitCode}
          email={email}
          errorMessage={errorMessage}
          setupMultifactor={setupMultifactor}
          backToSelect={this.backToSelect}
          resendCode={resendCode}
        />
      )
    } else {
      return (
        <Div className="setup-mfa-container">
          <H1 className="setup-mfa-header">Set up two-factor authentication to sign in</H1>
          <P className="setup-mfa-info">
            Once configured, you’ll be required to enter an authentication code in order
            to sign into your Drift account. You can receive authentication codes
            through {this.hasSmsMfa && "SMS text message or "}the Google Authenticator app.
          </P>
          {this.hasSmsMfa && <ProviderOption
            providerType={MFA_PROVIDERS.sms}
            providerTitle={"SMS text message"}
            providerImage={smsAuthIcon}
            providerText={"Receive a text message to your mobile device when signing in."}
            selectProvider={this.selectProvider}
          />}
          <ProviderOption
            providerType={MFA_PROVIDERS.google}
            providerTitle={"Google Authenticator"}
            providerImage={googleAuthIcon}
            providerText={"Use codes created by the Google Authenticator app on your device."}
            selectProvider={this.selectProvider}
          />
        </Div>
      )
    }
  }
}

export default MFASetupStep
