import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GoogleLogin from 'react-google-login'
import config from 'config'
import API from 'api/auth'
import './styles.css'
import { Div } from '@driftt/tide-core'

const GOOGLE_LOGIN_CLOSED_ERROR = "popup_closed_by_user"
const GOOGLE_LOGIN_ACCESS_DENIED_ERROR = "access_denied"

class GoogleAuth extends Component {

  static propTypes = {
    captureCode: PropTypes.func.isRequired,
    captureError: PropTypes.func.isRequired
  }

  handlePopUpError = (response) => {
    if (response.error === GOOGLE_LOGIN_CLOSED_ERROR) {
      window.analytics && window.analytics.track('Third-Party Login Refused', {
        anonymousId: window.analytics.user().anonymousId(),
        source: GOOGLE_LOGIN_CLOSED_ERROR
      })
    }
    else if (response.error === GOOGLE_LOGIN_ACCESS_DENIED_ERROR) {
      window.analytics && window.analytics.track('Third-Party Login Refused', {
        anonymousId: window.analytics.user().anonymousId(),
        source: GOOGLE_LOGIN_ACCESS_DENIED_ERROR
      })
    }
  }

  processGoogleCode = (authResult) => {
    if (authResult.code) {
      API.exchangeGoogleCodeForDriftCode(authResult.code, this.props.captureCode, this.props.captureError)
    }
  }

  render() {
    return (
      <Div className="google-container">
        <Div className="oauth-border">
          <p>or</p>
        </Div>
        <GoogleLogin
          clientId={config.GOOGLE_CLIENT_ID}
          responseType='code'
          accessType='offline'
          scope='https://www.googleapis.com/auth/calendar'
          buttonText='Sign in with Google'
          onSuccess={this.processGoogleCode}
          onFailure={this.handlePopUpError}
          theme='dark'
          className='google-button'
        />
      </Div>
    )
  }
}

export default GoogleAuth
