import React, { Component } from 'react'
import { Div, H1, P, Form, Input, AsyncButton, A } from '@driftt/tide-core';
import { Icon } from '@driftt/tide-icons'
import { withRouter, Link } from 'react-router-dom'
import { sendVerificationEmail } from 'api/findmyteam'
import { isEmailValid } from 'utils/email'

import verificationEmailSentSrc from 'assets/svg/email_sent.svg'
import './styles.css'

class FindYourTeamEmailVerification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      sentVerificationEmail: false,
      existingUserError: false
    }
  }

  verifyEmailRequest = () => {
    const { email } = this.state
    return sendVerificationEmail(email)
      .then(() => this.setState({ sentVerificationEmail: true }))
      .catch(err => {
        if (err.response.status === 400) {
          this.setState({ existingUserError: true })
        }
      })
  }

  setEmail = ({ target: { value }}) => {
    this.setState({ email: value })
  }

  resetState = () => {
    this.setState({
      email: '',
      sentVerificationEmail: false,
      existingUserError: false
    })
  }

  render() {
    const { email, sentVerificationEmail, existingUserError } = this.state
    return (
      <Div className="find-your-team-email">
      	<H1>Find your Drift Organization</H1>
        {!sentVerificationEmail && (
          <Div>
            <P className="verify-email-cta">
              We’ll send you a verification email to confirm your email address and
              find existing Drift organizations you can join.
            </P>
            <Form className="drift-form">
              <Input
                onChange={this.setEmail}
                minLength="10"
                size="large"
                type="email"
                placeholder="Your email address"
                required />
              <AsyncButton
                size="large"
                promise={this.verifyEmailRequest}
                disabled={!isEmailValid(email)}>
                Send verification email
              </AsyncButton>
            </Form>
            {existingUserError &&
              <P className="existing-user-error"> An existing user with that email already exists </P>
            }
            <Link
              className="solo-link password-login-step-back"
              to={{ pathname: "/login" }}>
              <Icon width={15} height={10} name="arrow-1-left" />
              Back
            </Link>
          </Div>
        )}
      {sentVerificationEmail &&
        <Div className="email-sent-success">
          <img src={verificationEmailSentSrc} alt="email verification sent" />
          <H1> Check your email! </H1>
          <P className="success-copy"> We’ve sent a verification email to {email}. Follow the instructions in the email to get started! </P>
          <P className="try-again-copy"> Didn’t receive an email? <A onClick={this.resetState}> Try a different email address </A></P>
        </Div>
      }
      </Div>
    )
  }
}

export default withRouter(FindYourTeamEmailVerification)
