import React from 'react'
import { Div } from '@driftt/tide-core'

import '../style.css'

const APP_STORE_URL = 'https://itunes.apple.com/us/app/drift-messaging-for-sales/id1117096112'
const IosAppSplash = () => (
  <Div className="splash-bg splash flex-column flex-space-between">
    <Div className="splash-content flex-column">
      <img className="drift-icon" alt="drift logo" src={require('assets/images/drift-logo-with-name.svg')} />
      <h3 className="splash-title">Looking for Drift mobile?</h3>
      <p className="splash-copy">Head over to the app store to chat with customers anywhere, anytime!</p>
      <Div className="flex-column app-button-container">
        <a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">  
          <img
            alt="app store badge"
            className="get-app-button-ios"
            src={require('assets/images/app-store-badge.svg')}
          />
        </a>
      </Div>
      <p className="splash-tooltip">{'👉'} Psst — our mobile app supports chat and video! Log in on a desktop device to access more features.</p>
    </Div>
    <Div className="splash-image splash-image-ios">
      <img className="splash-bolt-trio" alt="bolt trio" src={require('assets/images/bolt-trio-pink.svg')} />
      <img className="splash-phone-iphone" alt="iphone" src={require('assets/images/iphone-img.jpg')} />
    </Div>
  </Div>
)

export default IosAppSplash
