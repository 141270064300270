import React, { Component } from 'react'
import { Div, H5 } from '@driftt/tide-core';

import { Icon } from '@driftt/tide-icons'

import './styles.css'

class SessionTimeoutBanner extends Component {
    render () {
        const { hideSessionTimeoutBanner } = this.props

        return (
            <Div className="timeout-banner">
                <H5 className="timeout-banner-text">For your security, you were signed out of Drift due to inactivity.</H5>
                <Div className="timeout-banner-close-button" onClick={hideSessionTimeoutBanner}>
                    <Icon className="timeout-banner-close-icon" name="pb-close"/>
                </Div>
            </Div>
        )
    }
}

export default SessionTimeoutBanner
