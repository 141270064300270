import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Div, H2, P, A, AsyncButton, Button } from '@driftt/tide-core'
import { Select } from '@driftt/tide-selects'
import SelectableRole from '../SelectableRole'
import { chatOptions, seatTypes, defaultInviteInfo } from '../constants'
import driftLogo from 'assets/svg/logo.svg'
import driftLogoSmall from 'assets/svg/drift-logo.svg'
import backgroundSmall from 'assets/svg/grafitti-texture.svg'
import backgroundLarge from 'assets/svg/grafitti-texture-full.svg'
import bolts from 'assets/svg/bolts.svg'
import boltsFlipped from 'assets/svg/bolts-flipped.svg'
import IsMobile from 'ismobilejs'
import { merge, find, propEq } from 'ramda'
import './styles.css'

const landingBackgroundStyle =  {
  background: `url(${backgroundLarge})`,
  backgroundRepeat: 'no-repeat'
}

const selectRoleBackgroundStyle = () => {
  if (IsMobile.phone) {
    return ({
      background: `url(${backgroundSmall}) no-repeat`,
    })
  }
}

class SelectRolePage extends Component {
  getInviteInfo = (inviteInfo) => {
    return merge(defaultInviteInfo, inviteInfo)
  }

  renderLicenses = () => {
    const { licenses, isRoleSelected, selectRole, billingInterval } = this.props
    return licenses.map((license) => {
      if (seatTypes[license.type]) {
        return seatTypes[license.type].map((seatType) =>
          <SelectableRole
            roleSelected={isRoleSelected}
            selectRole={selectRole}
            roleName={seatType}
            license={license}
            billingInterval={billingInterval}
          />
        )
      }
      return null
    })
  }


  render() {
    const {
      inviteInfo,
      selectingRoles,
      chatTypeSelected,
      triggerRoleSelection,
      selectChatType,
      addTeammate,
      canAdd,
      isRoleSelected
    } = this.props

    const {
      orgName,
      prospectEmail
    } = this.getInviteInfo(inviteInfo)

    if (IsMobile.phone && !selectingRoles) {
        return (
          <Div className="mobile-landing-container drift-accept-request-container" style={landingBackgroundStyle}>
            <Div className="drift-logos">
              <img src={driftLogoSmall} alt="Drift" className="driftLogoSmall mobile-only" />
            </Div>
            <H2 className="mobile-landing-header">
              Someone wants to join
              <img src={bolts} alt="bolts" className="drift-bolts landing-page"/>
              <Div/>
              {orgName} in Drift
            </H2>
            <P className="landing-page-text">
              Click the button below to choose a role for
            </P>
            <P className="landing-page-email">
              {prospectEmail}👇
            </P>
            <Button
              className="choose-role-button"
              size="large"
              onClick={triggerRoleSelection}
            >
              Choose a role
            </Button>
          </Div>
        )
    } else {
      return (
        <Div className="drift-accept-request-app" style={selectRoleBackgroundStyle()}>
          <Div className="drift-accept-request-container">
            <Div className="drift-logos">
              <img src={driftLogo} alt="Drift" className="driftLogo desktop-only" />
              <img src={driftLogoSmall} alt="Drift" className="driftLogoSmall mobile-only" />
            </Div>
            {IsMobile.phone && <Div>
              <H2 className="accept-request-header mobile">Choose a role for:</H2>
              <P className="user-email mobile">{prospectEmail}</P>
            </Div>}
            {!IsMobile.phone && <Div>
              <H2 className="accept-request-header">
              <img src={boltsFlipped} alt="bolts" className="drift-bolts desktop-only"/>
                Someone wants to join {orgName} in Drift!
            </H2>
            <P className="select-role-info">
                Select a role for <A className="user-email">{prospectEmail}</A>
            </P>
            </Div>}
            {this.renderLicenses()}
            {isRoleSelected("Chat") && <Div>
              <Select
                className="chat-type-selector desktop-only"
                value={find(propEq('value', chatTypeSelected), chatOptions)}
                options={chatOptions}
                onChange={selectChatType}
              />
            </Div>}
            <AsyncButton
              className="add-teammate-button"
              size="large"
              disabled={!canAdd()}
              promise={addTeammate}
            >
              Add teammate
            </AsyncButton>
          </Div>
        </Div>
      )
    }
  }
}

export default withRouter(SelectRolePage)
