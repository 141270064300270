export const chatOptions = [
  {
    label: 'All conversations',
    value: 'all'
  },
  {
    label: 'Their conversations only',
    value: 'own'
  },
  {
    label: 'Their conversations and ones with no participants',
    value: 'ownPlus'
  }
]

export const BASE_ROLES = Object.freeze({
  ADMIN: 'Chat Admin',
  CHAT: 'Chat',
  VIDEO: 'Video',
  CALENDAR: 'Calendar',
  VIDEO_FREE: 'Free Video',
  REPORTING: 'Reporting',
  PLAYBOOKS: 'Playbooks',
})

export const CONVERSATION_ROLES = Object.freeze({
  ALL: "Chat All",
  ONLY_MINE: "Chat Only Mine",
  NO_PARTICIPANTS: "Chat With No Participants"
})

export const seatTypes = {
  REPORT_LICENSE: ["Reporting"],
  PLAYBOOK_LICENSE: ["Playbooks"],
  CALENDAR_LICENSE: ["Calendar"],
  VIDEO_LICENSE: ["Video"],
  CHAT_LICENSE: ["Admin", "Chat"]
}

export const displayName = {
  Calendar: "Calendar Only",
  Video: "Video User",
  Reporting: "Reporting Only",
  Playbooks: "Playbook Editor",
  Admin: "Admin User",
  Chat: "Chat User"
}

export const displayPrice = (price, billingInterval) => {
  const billingIntervalDisplayName = getBillingIntervalDisplayName(billingInterval)
  let priceInDollars = price/100
  if (priceInDollars % 1 === 0) {
    return `$${priceInDollars} / ${billingIntervalDisplayName}`
  }
  return priceInDollars.toLocaleString("en-US", {style:"currency", currency:"USD"}) + ` / ${billingIntervalDisplayName}`
}

const getBillingIntervalDisplayName = billingInterval => billingInterval === "annual" ? "Year" : "Month"

export const defaultInviteInfo = {
  orgName: "your Drift org",
  prospectEmail: "Unknown user"
}

export const buildInvitation = (email, role) => {
  const roleDetails = invitationDetails[role]
  return {
    licenses: roleDetails.licenses,
    user: {
      email: email,
      scopes: roleDetails.scopes,
      roles: roleDetails.roles
    }
  }
}

const invitationDetails = {
  Calendar: {
    licenses:["CALENDAR_LICENSE"],
    scopes:["calendar_user", "convo-ONLY_MINE"],
    roles: [BASE_ROLES.CALENDAR, CONVERSATION_ROLES.ONLY_MINE]
  },
  Video: {
    licenses: ["VIDEO_LICENSE"],
    scopes: ["video", "member"],
    roles: [BASE_ROLES.VIDEO, CONVERSATION_ROLES.ONLY_MINE],
  },
  Reporting: {
    licenses:["REPORT_LICENSE"],
    scopes:["placeholder"],
    roles: [BASE_ROLES.REPORTING]
  },
  Playbooks: {
    licenses: ["PLAYBOOK_LICENSE"],
    scopes: ["placeholder"],
    roles: [BASE_ROLES.PLAYBOOKS]
  },
  Admin: {
    licenses: [],
    scopes: ["org_admin", "convo-ALL", "member"],
    roles: [BASE_ROLES.ADMIN, CONVERSATION_ROLES.ALL],
  },
  allChat: {
    licenses: [],
    scopes:["agent", "convo-ALL", "member"],
    roles: [BASE_ROLES.CHAT, CONVERSATION_ROLES.ALL]
  },
  ownChat: {
    licenses: [],
    scopes: ["agent", "convo-ONLY_MINE", "member"],
    roles: [BASE_ROLES.CHAT, CONVERSATION_ROLES.ONLY_MINE]
  },
  ownPlusChat: {
    licenses: [],
    scopes:["agent", "convo-WITH_NO_PARTICIPANTS", "member"],
    roles: [BASE_ROLES.CHAT, CONVERSATION_ROLES.NO_PARTICIPANTS]
  }
}

export const isLicenseValid = (license) => {
  const {usedUnitsCount, totalUnitsCount, unitPriceInCents} = license
  return !voidLicense(totalUnitsCount, unitPriceInCents) &&
    !fullLicense(usedUnitsCount, totalUnitsCount, unitPriceInCents)
}

const voidLicense = (totalUnitsCount, unitPriceInCents) => {
  return totalUnitsCount === 0 && unitPriceInCents === 0
}

const fullLicense = (usedUnitsCount, totalUnitsCount, unitPriceInCents) => {
  return usedUnitsCount >= totalUnitsCount && unitPriceInCents === 0
}
