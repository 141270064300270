import React from 'react'
import { Div } from '@driftt/tide-core'

import '../style.css'

const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.drift.android.drift'
const AndroidAppSplash = () => (
  <Div className="splash-bg splash flex-column flex-space-between">
    <Div className="splash-content flex-column">
      <img className="drift-icon" alt="drift logo" src={require('assets/images/drift-logo-with-name.svg')} />
      <h3 className="splash-title">Looking for Drift mobile?</h3>
      <p className="splash-copy">Head over to the app store to chat with customers anywhere, anytime!</p>
      <Div className="flex-column app-button-container">
        <a href={PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
          <img
            alt="google play badge"
            className="get-app-button-android"
            src={require('assets/images/google-play-badge.png')}
          />
        </a>
      </Div>
      <p className="splash-tooltip">{'👉'} Psst — our mobile app supports chat! Log in on a desktop device to access more features.</p>
    </Div>
    <Div className="splash-image splash-image-android">
      <img className="splash-bolt-trio" alt="bolt trio" src={require('assets/images/bolt-trio-pink.svg')} />
      <img className="splash-phone-android" alt="android" src={require('assets/images/android-img.jpg')} />
    </Div>
  </Div>
)

export default AndroidAppSplash
