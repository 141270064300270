import React, { Component } from 'react'
import { Div, P } from '@driftt/tide-core'
import { displayPrice, displayName, isLicenseValid } from '../constants'
import upgrade from 'assets/svg/upgrade.svg'
import cx from 'classnames'
import "./styles.css"

class SelectableRole extends Component {

  get roleSelected() {
    return this.props.roleSelected(this.props.roleName)
  }

  get seatsAvailable() {
    const { usedUnitsCount, totalUnitsCount, unitPriceInCents } = this.props.license
    if (usedUnitsCount === totalUnitsCount) {
      return displayPrice(unitPriceInCents, this.props.billingInterval)
    } else if (this.roleSelected) {
      let newSeats = usedUnitsCount + 1
      return `${newSeats} / ${totalUnitsCount} seats used`
    }
    return `${usedUnitsCount} / ${totalUnitsCount} seats used`
  }

  selectRole = () => {
    this.props.selectRole(this.props.roleName)
  }

  upgradeLogo = () => {
    return <img className="upgrade-logo" alt="upgrade logo" src={upgrade}/>
  }


  render() {
    const {
      roleName,
      license
    } = this.props

    return (
      <Div>
      { isLicenseValid(license) ?
        <Div
          className={cx("selectable-role", {"role-selected": this.roleSelected})}
          onClick={this.selectRole}
        >
           {displayName[roleName]}
           <P className="role-availability">{this.seatsAvailable}</P>
        </Div>
      :
        <Div
         className="selectable-role disabled"
        >
          {this.upgradeLogo()}
          {displayName[roleName]}
          <P className="role-availability disabled">0 available</P>
        </Div>
      }
      </Div>
    )
  }
}

export default SelectableRole
