import React from 'react'
import { Div, H1, H3, P, AsyncButton, A } from '@driftt/tide-core'

import driftLogo from 'assets/svg/logo.svg'
import defaultOrgLogo from 'assets/svg/fallback-company-logo.svg'
import emptyStatePlaceholder from 'assets/svg/empty-state.svg'

const MAX_COMPANY_NAME_LENGTH = 15

const trimName = (orgName) => {
  return orgName.length > MAX_COMPANY_NAME_LENGTH ? `${orgName.substring(0, MAX_COMPANY_NAME_LENGTH)}...` : orgName
}

const renderOrg = (org, onJoin) => (
  <Div className="org-info-container">
    <Div className="org-logo-container">
      <img src={org.orgLogo || defaultOrgLogo} alt="org logo" width="32"/>
    </Div>
    <Div className="org-properties-container">
      <H3 className="org-name"> {trimName(org.orgName)} </H3>
      <P className="org-count"> {org.teammateCount} {org.teammateCount > 1 ? 'teammates' : 'teammate'} </P>
    </Div>
    <Div className="org-join-container">
      <AsyncButton
        className="join-button"
        size="small"
        promise={async () => onJoin(org.orgName, org.authorizedDomain.orgId)}>
        Join
      </AsyncButton>
    </Div>
  </Div>
)

const tableOfDomains = (orgs, onJoin) => (
  <Div>
    <P className="domain-info">
      Anyone with a <strong>{orgs[0].authorizedDomain.domain}</strong> email domain can
      request to join the following Drift organization{orgs.length > 1 && 's'}:
    </P>

    {orgs.map(org => renderOrg(org, onJoin))}
  </Div>
)

const FindYourTeamOrgTable = ({ domains, onJoin, redirectToVerifyEmailStep }) => (
  <Div className="drift-find-app">
    <Div className="drift-find-container">
      <img src={driftLogo} alt="Drift" className="logo" />
      {domains.length > 0 &&
        <Div className="drift-find-content">
          <H1 className="drift-join-title"> Join your Drift Organization </H1>
          {tableOfDomains(domains, onJoin)}
        </Div>
      }
      {domains.length === 0 &&
        <Div className="drift-not-found-content">
          <H1 className="drift-not-found-title"> We couldn’t find any Drift organizations for you to join </H1>
          <P className="drift-not-found-copy"> It looks like your email address isn't associated with any Drift organizations. </P>
          <img src={emptyStatePlaceholder} alt="" className="empty-state-img" width={180} />
        </Div>
      }
    </Div>
    <Div className="drift-tooltip-container">
      <Div className="drift-tooltip-content">
        <P>
          Looking for a different Drift organization?
          <A onClick={redirectToVerifyEmailStep}> Try another email address </A>
          or ask your Drift admin for an invitiation.
        </P>
      </Div>
    </Div>
  </Div>
)

export default FindYourTeamOrgTable
