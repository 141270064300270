import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Div, H2, P, A } from '@driftt/tide-core'
import driftLogo from 'assets/svg/logo.svg'
import driftLogoSmall from 'assets/svg/drift-logo.svg'
import { MissingFeatures } from '@driftt/billing-components'
import boltsFlipped from 'assets/svg/bolts-flipped.svg'
import config from 'config'
import './styles.css'

const upgradePlan = () => {
  window.open(`${config.APP_ROOT}/settings/billing`, "_blank")
}

const missingFeatures = [
  {
    name: "Live View",
    description: "See exactly when visitors are on your website and message them in real time.",
    upgradePlan: "Standard"
  },
  {
    name: "Chat Playbooks",
    description: "Create full screen takeovers, custom welcome messages, email capture and more targeted to specific parts of your site.",
    upgradePlan: "Standard"
  },
  {
    name: "Powerful Bots",
    description: "Your own custom bot that talks to your visitors 24/7 and qualifies them for you.",
    upgradePlan: "Pro"
  },
  {
    name: "Automated Meetings",
    description: "Have the LeadBot book meetings for you automatically, even while you sleep.",
    upgradePlan: "Pro"
  }
]

class FreeUserPage extends Component {

  render() {
    const { email } = this.props

    return (
      <Div className="drift-accept-success-container free-landing-page">
        <Div className="drift-logos">
          <img src={driftLogo} alt="Drift" className="driftLogo desktop-only" />
          <img src={driftLogoSmall} alt="Drift" className="driftLogoSmall mobile-only" />
        </Div>
        <Div className="upgrade-actions">
          <Div className="upgrade-to-invite">
            <H2 className="upgrade-header">
              <img src={boltsFlipped} alt="bolts" className="drift-bolts free-landing desktop-only"/>
              Upgrade to invite teammates
            </H2>
            <P className="upgrade-info">
              You’re currently on the Free Plan and have reached your seat limit.
              Upgrade to a Standard Plan or above to add <A className="user-email">{email}</A> and
              others to your Drift organization!
            </P>
          </Div>
          <Div className="missing-features-wrapper">
            <MissingFeatures
              upgradePlan={upgradePlan}
              isFetchingPlans={false}
              missingFeatures={missingFeatures}
            />
          </Div>
        </Div>
      </Div>
    )
  }
}

export default withRouter(FreeUserPage)
