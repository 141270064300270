import React, { Component } from 'react'
import API from '../../../api/auth'
import MessageConstants from '../../../constants/MessageConstants.js'
import './style.css'
import { Div, H1, P, Button, Form, Input, Colorway } from '@driftt/tide-core';
import * as recaptcha from 'utils/recaptcha'

import { Icon } from '@driftt/tide-icons'

class ForgotPassword extends Component {

  constructor(props){
    super(props)

    const defaultEmail = this.props.location.state ? this.props.location.state.forgotPasswordState : ''
    const defaultMessage = this.props.location.state ? this.props.location.state.error || '' : ''

    this.state = {
      requestStatus: null,
      email: defaultEmail,
      error: defaultMessage,
      success: false,
      loading: false,
      solvedCaptcha: false
    }
    this.submitSendReset = this.submitSendReset.bind(this)
    this.handleEmailInput = this.handleEmailInput.bind(this)
  }

  handleEmailInput(event) {
    this.setState({ email: event.target.value, error: '' })
  }

  setSolvedCaptcha = (value) => {
    this.setState({ solvedCaptcha: value })
  }

  componentDidMount() {
    recaptcha.initialize(this.setSolvedCaptcha)
  }

  submitSendReset(event){
    event.preventDefault()
    const resetCallback = (response) => {
      if (response.status === 200) {
        this.setState({ success: true, error: '', loading: false })
      } else if (response.status === 403) {
        this.setState({ error: MessageConstants.CAPTCHA_TOKEN_EXPIRED, loading: false }, recaptcha.reset)
      }
      else {
        this.setState({ error: MessageConstants.DEFAULT_SERVER_ERROR, loading: false}, recaptcha.reset)
      }
    }

    this.setState({ loading: true }, () => API.sendReset(this.state.email, recaptcha.getToken(), resetCallback))
  }

  renderSuccess(){
    return (
      <Div className="forgot-success">
        <Colorway className="forgot-password-success-colorway">
          <Icon name="cw-invite-success" />
        </Colorway>
        <H1 className="password-reset-header">Password reset</H1>
        <P>{ MessageConstants.SENT_PASSWORD_RESET }</P>
      </Div>
    )
  }

  render() {
    if (this.state.success) return this.renderSuccess()
    const { loading, email, solvedCaptcha } = this.state
    const submitDisabled = !solvedCaptcha || !email
    return (
      <Div className="forgot-password-page">
      	<H1>Reset password</H1>
        <P>{MessageConstants.FORGOT_HELPER}</P>
        <br/>
        <Form className="drift-form">
          <Input onChange={this.handleEmailInput} defaultValue={ this.state.email } size="large" type="email" placeholder="Your email" required />
          {this.state.error && <strong className="request-status error">{ this.state.error }</strong> }
          <div className="recaptcha-container">
            <div id="g-recaptcha" />
          </div>
          <Button size="large" onClick={this.submitSendReset} disabled={submitDisabled} loading={loading}>Send reset email</Button>
        </Form>
        <Button
          className="solo-link"
          type="utility"
          icon="arrow-1-left"
          href="/login"
        >
          Back
        </Button>
      </Div>
    )
  }
}

export default ForgotPassword
