import React, { Component } from 'react'
import { H1, Div, P, } from '@driftt/tide-core'

import './styles.css'


class Goodbye extends Component {

  componentDidMount = () => {
    const { hideLoginStep } = this.props
    hideLoginStep()
  }

  render() {
    return (
      <Div className="goodbye-contents">
        <H1>Your account was deleted</H1>
          <Div className="goodbye-message">
            <P>
              Thank you so much for being a Drift customer <span role="img" aria-label="blue heart">💙</span>  We appreciate the opportunity to help your business.
            </P>
          </Div>
      </Div>
    )
  }
}

export default Goodbye