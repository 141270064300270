import React, { Component } from 'react'
import { Div, H2, P } from '@driftt/tide-core'
import "./styles.css"


class SetupInstruction extends Component {

  render() {
    const {
      stepImage,
      stepText,
      stepNumber,
      stepTitle,
      qrCode
    } = this.props

    return (
      <Div className="instruction-container">
        <Div className="step-image">
          { qrCode ? stepImage : <img src={stepImage} alt="demonstration" /> }
        </Div>
        <Div className="step-info">
          <P>Step {stepNumber}</P>
          <H2>{stepTitle}</H2>
          <P>{stepText}</P>
        </Div>
      </Div>
    )
  }
}

export default SetupInstruction
