import config from 'config'
import { billingApiClient, customerKongApiClient, customerApiClient } from 'api'

// TODO: use central-auth-api here
export const sendVerificationEmail = (email) => {
  return customerKongApiClient.post(`/findTeam/verifyEmail`, { email })
}

export const retrieveDomains = (token) => {
  return customerKongApiClient.post('/findTeam/getDomains', { token })
    .then(response => response.data)
}

export const joinOrg = (orgId, token) => {
  return customerKongApiClient.post(`/findTeam/joinOrg?orgId=${orgId}`, { token })
}

export const getTransferOrgName = (token) => {
  return customerKongApiClient.post(`/users/transferOrg`, { token })
    .then(response => response.data)
}

export const transferUser = (token) => {
  return customerKongApiClient.post(`/users/transferMe?clientId=${config.DEFAULT_CLIENT_ID}`, { token })
    .then(response => response.data)
}

export const addTeammate = (payload, orgId, token) => {
  return customerApiClient.post(`/organizations/${orgId}/users/invite/relay?relayToken=${token}`, payload)
}

export const getBillingInfoAndRelay = (relayToken, captureError) => {
  return billingApiClient.get(`/info/relay?relayToken=${relayToken}`)
    .then(response => response.data)
    .catch(error => captureError(error))
}

export const hasMultiOrg = (token) => {
  return customerKongApiClient.post('/multi_org/state/relay', { token })
}
