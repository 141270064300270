import React, { Component } from 'react'
import { find, propEq } from 'ramda'
import SetupInstruction from '../SetupInstruction'
import { Button, Input, Div, H1, P, A } from '@driftt/tide-core';
import { Select } from '@driftt/tide-selects'
import { Icon } from '@driftt/tide-icons'
import { Link } from 'react-router-dom'
import devices from 'assets/svg/devices.svg'
import smsCode from 'assets/svg/smsbig.svg'
import { countryCodes } from './constants'
import './styles.css'

class SMSAuthSetupStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      setupStep: "ENTER_NUMBER",
      countrySelected: {
        value: "+1",
        label: "United States +1"
      },
      phoneNumber: "",
      codeResent: false
    }
  }

  getPhoneNumber = () => {
    const { countrySelected, phoneNumber } = this.state
    return countrySelected.value + phoneNumber
  }

  setCode = ({ target }) => {
    this.props.setCode(target.value)
  }

  setPhoneNumber = ({ target }) => {
    this.setState({ phoneNumber: target.value })
  }

  selectCountry = (countrySelected) => {
    this.setState({ countrySelected })
  }

  resendCode = () => {
    this.setState({ codeResent: true })
    this.props.resendCode();
  }

  getCodeSentText = () => {
    return(
      <Div>
        <P>
          We sent a 6-digit verification code to your phone via text.
        </P>
        { this.state.codeResent ?
          <P className="code-resent">Code resent</P>
          :
          <A className="show-code-text" onClick={this.resendCode}>Resend code</A>
        }
      </Div>
    )
  }

  nextStep = () => {
    this.props.setupMultifactor(this.getPhoneNumber())
    this.setState({ setupStep: "ENTER_CODE" })
  }

  render() {
    const {
      setupStep,
      countrySelected,
      phoneNumber
    } = this.state

    const {
      multifactorCode,
      submitCode,
      errorMessage,
      backToSelect
    } = this.props

    if (setupStep === "ENTER_NUMBER") {
      return (
        <Div className="setup-mfa-container">
          <Link
            className="solo-link mfa-back-button"
            to={{}}
            onClick={backToSelect}>
            <Icon width={12} height={8} name="arrow-1-left" />
            Back
          </Link>
          <H1 className="setup-mfa-header no-margin">Set up SMS Authentication</H1>
          <P className="setup-mfa-info sms">
            Once configured, you’ll be required to enter an authentication code
            in order to sign into your Drift account.
          </P>
          <SetupInstruction
            stepTitle={"Enter your mobile phone number below"}
            stepText={"We’ll send a time-sensitive code to your phone via text message to verify your number."}
            stepNumber={1}
            stepImage={devices}
          />
          <Select
            label="Cell phone number"
            className="select-country"
            value={find(propEq('value', countrySelected), countryCodes)}
            options={countryCodes}
            onChange={this.selectCountry}
          />
          <Input
            size="medium"
            value={phoneNumber}
            onChange={this.setPhoneNumber}
            className="mfa-code-input"
            placeholder="5555555555"
          />
          <Button
            className="mfa-next-step-button add-phone"
            size="medium"
            onClick={this.nextStep}
            disabled={!phoneNumber}
          >
            Add phone number
          </Button>
        </Div>
      )
    } else if (setupStep === "ENTER_CODE") {
      return (
        <Div className="setup-mfa-container">
        <H1 className="setup-mfa-header">Set up SMS Authentication</H1>
        <P className="setup-mfa-info sms">
          Once configured, you’ll be required to enter an authentication code
          in order to sign into your Drift account.
        </P>
          <SetupInstruction
            stepTitle={"Enter your verification code below"}
            stepText={this.getCodeSentText()}
            stepNumber={2}
            stepImage={smsCode}
          />
          <Input
            size="medium"
            value={multifactorCode}
            onChange={this.setCode}
            className="mfa-code-input phone"
            placeholder="123456"
          />
          { !!errorMessage &&
            <strong className="request-status error setup">
              {errorMessage}
            </strong>
          }
          <Button
            className="mfa-next-step-button add-phone"
            size="medium"
            onClick={submitCode}
            disabled={!multifactorCode}
          >
            Verify code and enable
          </Button>
        </Div>
      )
    }
  }
}

export default SMSAuthSetupStep
