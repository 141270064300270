import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Div, H2, P, A } from '@driftt/tide-core'
import driftLogo from 'assets/svg/logo.svg'
import driftLogoSmall from 'assets/svg/drift-logo.svg'
import backgroundLarge from 'assets/svg/grafitti-texture-full.svg'
import alreadyAdded from 'assets/svg/alreadyadded.svg'
import IsMobile from 'ismobilejs'
import './styles.css'

const backgroundStyle = () => {
  if (IsMobile.phone) {
    return {
      background: `url(${backgroundLarge})`,
      backgroundRepeat: 'no-repeat'
    }
  }
}

class AlreadyAddedPage extends Component {

  render() {
    return (
      <Div className="drift-accept-success-container" style={backgroundStyle()}>
        <Div className="drift-logos">
          <img src={driftLogo} alt="Drift" className="driftLogo desktop-only" />
          <img src={driftLogoSmall} alt="Drift" className="driftLogoSmall mobile-only" />
        </Div>
        <Div>
          <img src={alreadyAdded} alt="user-added" className="email-icon"/>
        </Div>
        <Div>
          <H2 className="invite-sent-header">Looks like this user is already in your Drift organization!</H2>
        </Div>
        <P className="invite-sent-text">
          <A href="/login" className="sign-in-ref">Sign in </A> to Drift to manage your teammates
        </P>
      </Div>
    )
  }
}

export default withRouter(AlreadyAddedPage)
