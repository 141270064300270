import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Div, H3, P, AsyncButton } from '@driftt/tide-core';
import { Icon } from '@driftt/tide-icons'
import SelectableType from './SelectableType'
import driftLogo from 'assets/svg/logo.svg'
import driftLogoSmall from 'assets/svg/drift-logo.svg'
import backgroundSmall from 'assets/svg/grafitti-texture.svg'
import './styles.css'
import { chatOptions } from '../constants'

const backgroundStyle =  {
  background: `url(${backgroundSmall})`,
  backgroundRepeat: 'no-repeat'
}

class ChatUserPage extends Component {
  isTypeSelected = type => {
    return this.props.chatTypeSelected.value === type.value;
  }

  selectChatType = type => {
    this.props.selectChatType(type)
  }

  renderChatTypes = () => {
    return chatOptions.map( (option) =>
      <SelectableType
        chatType={option}
        isSelected={this.isTypeSelected}
        selectChatType={this.selectChatType}
      />
    )
  }

  render() {
    const {
      prospectEmail,
      addTeammate,
      selectBackMobile
    } = this.props

    return (
      <Div className="drift-accept-request-app" style={backgroundStyle}>
        <Div className="drift-accept-request-container">
        <Div className="drift-logos chat">
          <img src={driftLogo} alt="Drift" className="driftLogo desktop-only" />
          <img src={driftLogoSmall} alt="Drift" className="driftLogoSmall mobile-only" />
        </Div>
          <Div className="chat-user-back" onClick={selectBackMobile}>
            <Icon name="arrow-1-left" className="back-icon"/>
            {" Back"}
          </Div>
          <H3 className="chat-user-header">Chat User</H3>
          <P className="user-email mobile">{prospectEmail}</P>
          <P className="select-role-info chat">
            Which conversations should this user have access to?
          </P>
          {this.renderChatTypes()}
          <AsyncButton
            className="add-teammate-button chat"
            size="large"
            promise={addTeammate}
          >
            Add teammate
          </AsyncButton>
        </Div>
      </Div>
    )
  }
}

export default withRouter(ChatUserPage)
