import React, { Component } from 'react'
import { Div, P } from '@driftt/tide-core'
import cx from 'classnames'

class SelectableType extends Component {

  get isSelected() {
    return this.props.isSelected(this.props.chatType)
  }

  selectChatType = () => {
    this.props.selectChatType(this.props.chatType)
  }

  render() {
    const {
      chatType
    } = this.props

    return (
      <Div
        className={cx("selectable-role", "chat", {"role-selected": this.isSelected})}
        onClick={this.selectChatType}
      >
        <P className="chat-text">{chatType.label}</P>
      </Div>
    )
  }
}

export default SelectableType
