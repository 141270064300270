import React, { Component } from 'react'
import { Button, Input, H1, Div, P, A } from '@driftt/tide-core'
import config from 'config'
import PropTypes from 'prop-types'
import './styles.css'

const CONSTANTS_MAPPING = Object.freeze({
  DRIFT_VIDEO: {
    CREATE_COPY: 'Want to get started with Drift Video? ',
    CREATE_URL: `${config.DRIFT_VIDEO_URL}/accounts/login-redirect?access_type=start.drift.com/login`,
  },
})

class EmailLoginStep extends Component {
  static propTypes = {
    email: PropTypes.string,
    setEmail: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isSubmittingEmail: PropTypes.bool,
    errorMessage: PropTypes.string,
    clientId: PropTypes.string,
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit()
  }

  setEmail = ({ target }) => {
    this.props.setEmail(target.value)
  }

  render() {
    const {
      email,
      title,
      isSubmittingEmail,
      errorMessage,
      clientId,
      emailVerifiedMessage,
    } = this.props
    const isDriftVideo =
      clientId === config.DRIFT_VIDEO_CLIENT_ID ||
      clientId === config.DRIFT_VIDEO_CLIENT_ID_DRIFT
    const linkConstants = CONSTANTS_MAPPING[isDriftVideo ? 'DRIFT_VIDEO' : '']
    return (
      <Div className="email-login-step">
        {!!emailVerifiedMessage && (
          <Div className="email-verified-message">{emailVerifiedMessage}</Div>
        )}
        <H1>{title}</H1>
        <form className="drift-form" onSubmit={this.onSubmit} noValidate>
          <Input
            size="large"
            onChange={this.setEmail}
            value={email}
            className="large email-form"
            id="username"
            name="username"
            type="email"
            placeholder="Your email"
            required
            autoFocus
            autoComplete="on"
          />
          {errorMessage && (
            <p className="request-status error">{errorMessage} </p>
          )}
          <Button
            size="large"
            onClick={this.onSubmit}
            loading={isSubmittingEmail}
            disabled={!email}
          >
            Next
          </Button>
        </form>
        {!isDriftVideo && (
          <P className="join-company-copy">
            Trying to join your company on Drift?{' '}
            <A href={`${config.LOGIN_ROOT}/findTeam/verifyEmail`}>
              Find an existing Drift organization
            </A>
          </P>
        )}
        {isDriftVideo && (
          <P className="create-account-copy">
            {linkConstants.CREATE_COPY}
            <A href={linkConstants.CREATE_URL}>Create an account</A>
          </P>
        )}
      </Div>
    )
  }
}

export default EmailLoginStep
