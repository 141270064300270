import config from 'config'
 import axios from 'axios'

export const customerApiClient = axios.create({
    baseURL: config.CUSTOMER_API,
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json; charset=utf-8',
    },
})

export const customerKongApiClient = axios.create({
  baseURL: config.KONG_CUSTOMER_API,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

export const billingApiClient = axios.create({
  baseURL: config.BILLING_API,
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
  },
})

export const authApiClient = axios.create({ baseURL: config.AUTH_API })
