import React, { Component } from 'react'
import urlParse from 'url-parse'
import { withRouter } from 'react-router-dom'
import { Div } from '@driftt/tide-core'
import LoadingPage from 'components/shared/LoadingPage'
import FindYourTeamOrgTable from './OrgTable'
import AdminEmailed from './AdminEmailed'
import { retrieveDomains, joinOrg } from 'api/findmyteam'
import './styles.css'

const STATUS_TYPE = {
  LOADING: 0,
  JOINING: 1,
  REQUEST_SENT: 2
}

const parseToken = (url) => {
  const shouldParseQuery = true
  return urlParse(url, shouldParseQuery).query.token
}

const redirectToVerifyEmailStep = () => {
  window.location.replace('/findTeam/verifyEmail')
}

class FindYourTeamJoin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: STATUS_TYPE.LOADING,
      token: '',
      domains: [],
      joinRequest: {
        success: false,
        orgName: ''
      }
    }
  }

  componentDidMount() {
    const token = parseToken(this.props.location.search)
    if (token) {
      retrieveDomains(token).then(data => {
        this.setState({ domains: data, token: token, status: STATUS_TYPE.JOINING })
      }).catch(() => {
        redirectToVerifyEmailStep()
      })
    }
    else {
      redirectToVerifyEmailStep()
    }
  }

  onJoin = (orgName, orgId) => {
    const { token } = this.state
    return joinOrg(orgId, token).then(() => {
      this.setState({ joinRequest: { success: true, orgName: orgName }, status: STATUS_TYPE.REQUEST_SENT })
    }).catch(() => redirectToVerifyEmailStep())
  }

  render() {
    const { status, joinRequest, domains } = this.state
    return (
      <Div>
        { status === STATUS_TYPE.LOADING &&
          <LoadingPage />
        }
        { status === STATUS_TYPE.JOINING &&
          <FindYourTeamOrgTable
            domains={domains}
            onJoin={this.onJoin}
            redirectToVerifyEmailStep={redirectToVerifyEmailStep}
          />
        }
        { status === STATUS_TYPE.REQUEST_SENT &&
          <AdminEmailed orgName={joinRequest.orgName}/>
        }
      </Div>
    )
  }
}

export default withRouter(FindYourTeamJoin)
