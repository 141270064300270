import React from 'react'
import { Div, H1, P } from '@driftt/tide-core'
import requestEmailSentSrc from 'assets/images/forgetpassword_sent.svg'

const AdminEmailed = ({ orgName }) => (
  <Div className="drift-find-app">
    <Div className="email-sent-container">
      <img src={requestEmailSentSrc} alt="email request sent" />
      <H1> You've requested to join {orgName} in Drift! </H1>
      <P className="success-copy">
        We’ve sent a confirmation email to your Drift admin. Once they approve your request,
        you’ll receive an email from us to get started!
      </P>
    </Div>
  </Div>
)

export default AdminEmailed
