import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Div } from '@driftt/tide-core'
import MFAProviderStep from './MFAProviderStep'
import MFASetupStep from './MFASetupStep'
import { MFA_PROVIDERS } from '../constants.js'
import './style.css'

class MultifactorLoginStep extends Component {

  static propTypes = {
    errorMessage: PropTypes.string,
    code: PropTypes.string,
    multifactorProvider: PropTypes.string.isRequired,
    setCode: PropTypes.func.isRequired,
    submitCode: PropTypes.func.isRequired,
  }

  componentWillMount() {
    if (this.props.multifactorProvider === MFA_PROVIDERS.notSetup) {
      this.setState({ mfaSetup: false })
    } else {
      this.setState({ mfaSetup: true })
    }
  }

  get mfaIsSetup() {
    return this.state.mfaSetup
  }

  render() {
    const {
      errorMessage,
      multifactorProvider,
      setCode,
      setMultifactorProvider,
      submitCode,
      multifactorCode,
      multifactorSecret,
      email,
      setupMultifactor,
      resendCode,
      availableProviders
    } = this.props

    return (
      <Div>
          { this.mfaIsSetup ?
            <MFAProviderStep
              code={multifactorCode}
              setCode={setCode}
              submitCode={submitCode}
              errorMessage={errorMessage}
              provider={multifactorProvider}
            />
            :
            <MFASetupStep
              setCode={setCode}
              setMultifactorProvider={setMultifactorProvider}
              setupMultifactor={setupMultifactor}
              multifactorCode={multifactorCode}
              submitCode={submitCode}
              multifactorSecret={multifactorSecret}
              email={email}
              errorMessage={errorMessage}
              availableProviders={availableProviders}
              resendCode={resendCode}
            />
          }
      </Div>
    )
  }
}

export default MultifactorLoginStep
