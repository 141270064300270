import React, { Component } from 'react'
import linkIcon from 'assets/svg/link-copied.svg'
import { withRouter } from 'react-router-dom'
import { Div, H1, P } from '@driftt/tide-core'
import { copyReinviteLink } from 'api/invite'
import urlParse from 'url-parse'
import Clipboard from 'react-clipboard-polyfill';

import './style.css'

class InviteLinkCopied extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorOccured: false
        }
    }

    copyLink = (copyText, token, orgId, email) => {
        if(token && orgId && email){
            copyReinviteLink(token, orgId, email)
            .then(json => {
                copyText(json.data.link)
            })
            .catch(err => {
                this.setState({
                    errorOccured: true
                })
                console.error("Error occured while getting invite link: " + err)
            })
        }
        else{
            console.error("Not enough information provided to get invitation link")
            this.setState({
                errorOccurred: true
            })
        }
    }

    shouldComponentUpdate (prevProps, prevState) {
        return prevState.errorOccured !== this.state.errorOccured
    }

    inviteLinkPage = ({ copyText }) => {
        const urlQueryParams = urlParse(this.props.location.search, true).query
        const token = urlQueryParams.token
        const email = urlQueryParams.inviteEmail
        const orgId = urlQueryParams.orgId

        this.copyLink(copyText, token, orgId, email)

        const { errorOccured } = this.state
        return (
            <Div className="invite-link-copied-page">
                <Div className="invite-link-copied-content-container">
                    <Div className="link-icon-container">
                        <img src={linkIcon} alt="Link copied" className="link-copied-icon"/>
                    </Div>
                    {!errorOccured ?
                        <Div>
                            <H1>Invitation link copied!</H1>
                            <P>Paste and share this link with {email} so they can activate their Drift account! You can now close this window - happy Drifting!</P>
                        </Div>
                    :
                        <Div>
                            <H1>Failed to copy invitation link...</H1>
                            <P>Please visit the teammates page within the Drift app to copy the invitation link for {email}. Sorry for the inconvenience!</P>
                        </Div>
                    }
                </Div>
            </Div>
        )
    }

    render () {
        return <Clipboard component={this.inviteLinkPage}/>
    }
}

export default withRouter(InviteLinkCopied)
