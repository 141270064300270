import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Loader } from '@driftt/tide-core'
import SelectRolePage from './SelectRolePage'
import SuccessPage from './SuccessPage'
import AlreadyAddedPage from './AlreadyAddedPage'
import FreeUserPage from './FreeUserPage'
import ChatUserPage from './ChatUserPage'
import IsMobile from 'ismobilejs'
import { setSnippets } from 'utils/snippets'
import urlParse from 'url-parse'
import { chatOptions, buildInvitation } from './constants'
import API from 'api/auth'
import { getBillingInfoAndRelay, addTeammate } from 'api/findmyteam'

class AcceptRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      relayToken: urlParse(this.props.location.search, true).query.token,
      selectingRoles: false,
      dataLoaded: false,
      userPreviouslyAdded: false,
      chatTypeSelected: chatOptions[0],
      teammateAdded: false,
      chatSelectedMobile: false
    }
  }

  async componentDidMount() {
    await this.getInviteInfo()
    await this.getBillingInfo()
    this.setState({
      dataLoaded: true,
    })
  }

  get getRole() {
    if (this.isRoleSelected("Chat")) {
      return this.state.chatTypeSelected.value + this.state.roleSelected
    } else {
      return this.state.roleSelected
    }
  }

  getBillingInfo = async () => {
    const { data, relayState } =  await getBillingInfoAndRelay(this.state.relayToken, this.captureError)
    this.setState({
      licenses: data.licenses,
      billingInterval: data.plans.messagingPlan.interval,
      relayToken: relayState.token
    })
    if (data.plans.messagingPlan.name === "Free") {
      this.setState({freeOrg: true})
    }
  }

  getInviteInfo = async () => {
     const { data : inviteInfo } = await API.getRelayTokenUserInfo(this.state.relayToken, this.captureError)
     this.setState({ inviteInfo })

  }

  captureError = (error) => {
    this.setState({userPreviouslyAdded: true})
  }

  isRoleSelected = (role) => {
    return this.state.roleSelected === role;
  }

  selectRole = (role) => {
    this.setState({roleSelected: role})
    if(role === "Chat" && IsMobile.phone) {
      this.setState({ chatSelectedMobile: true})
    }
  }

  selectBackMobile = () => {
    this.setState({
      chatSelectedMobile: false,
      roleSelected: ""
    })
  }

  selectChatType = (type) => {
    this.setState({chatTypeSelected: type})
  }

  canAdd = () => {
    if (this.isRoleSelected("Chat")) {
      return !!this.state.chatTypeSelected;
    }
    return !!this.state.roleSelected;
  }

  addTeammate = async () => {
    const { inviteInfo : { adminOrgId, prospectEmail }, relayToken } = this.state
    const role = this.getRole
    const payload = {
      invitations: [buildInvitation(prospectEmail, role)]
    }
    this.trackUsage()
    return addTeammate(payload, adminOrgId, relayToken)
    .then(() => this.setState({ teammateAdded : true }))
    .catch((err) => {
      this.trackError(err)
      console.error(err)
      this.setState({ userPreviouslyAdded : true })
    })

  }

  trackUsage = () => {
    setSnippets()
    window.analytics.page('User Invited', {
      inviteSource: "Find My Team Landing",
      version: 'new'
    })
  }

  trackError = (error) => {
    setSnippets()
    window.analytics.page('Error on Invite', {
      inviteSource: "Find My Team Landing",
      error: error
    })
  }

  triggerRoleSelection = () => {
    this.setState({selectingRoles: true})
  }

  render() {
    const {
      inviteInfo,
      licenses,
      selectingRoles,
      chatTypeSelected,
      dataLoaded,
      userPreviouslyAdded,
      roleSelected,
      teammateAdded,
      chatSelectedMobile,
      freeOrg,
      billingInterval
    } = this.state


    if (userPreviouslyAdded) {
      return (<AlreadyAddedPage/>)
    }

    if (freeOrg) {
      return (<FreeUserPage email={inviteInfo.prospectEmail}/>)
    }

    if (!dataLoaded) {
      return (<Loader/>)
    }

    if (teammateAdded) {
      return (<SuccessPage
        prospectEmail={inviteInfo.prospectEmail}
        userType={roleSelected}
      />)
    }

    if (chatSelectedMobile) {
      return (<ChatUserPage
        prospectEmail={inviteInfo.prospectEmail}
        chatTypeSelected={chatTypeSelected}
        selectChatType={this.selectChatType}
        addTeammate={this.addTeammate}
        selectBackMobile={this.selectBackMobile}
      />)
    }

    return (<SelectRolePage
      inviteInfo={inviteInfo}
      selectingRoles={selectingRoles}
      chatTypeSelected={chatTypeSelected}
      licenses={licenses}
      billingInterval={billingInterval}
      triggerRoleSelection={this.triggerRoleSelection}
      selectChatMobile={this.selectChatMobile}
      selectChatType={this.selectChatType}
      addTeammate={this.addTeammate}
      canAdd={this.canAdd}
      isRoleSelected={this.isRoleSelected}
      selectRole={this.selectRole}
    />)

    }
}

export default withRouter(AcceptRequest)
