import { authApiClient } from 'api'
import config from 'config'

export const tryResolveLoginPageUrl = ({ email }) => {
  return authApiClient.post(`/sso/saml/enabled?clientId=${config.DEFAULT_CLIENT_ID}`, { email })
    .then(response => response.data)
}

export const resolveSsoRedirectUrl = ({ email, nextUrl, authClientId }) => {
  const payload = { email, postLoginUrl: nextUrl, authClientId }
  return authApiClient.post(`/sso/saml/login-url`, payload)
    .then(response => response.data.url)
}

export const fetchSsoErrorRecord = ({ requestId }) => {
  return authApiClient.get(`/sso/saml/error/${requestId}`)
    .then(response => response.data)
}